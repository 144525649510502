import { captureException } from '@sentry/react';
import { useEffect } from 'react';

import { config, ThemeName, themeNames } from 'appConfig';
import { PermissionNode } from '__generated__/graphql';
import { permissionsVar, settingsVar, userThemeVar } from 'client/cache';
import { FrontendSettingNode } from 'typeDeclarations/graphql/nodes';
import { AppSettingsMap } from 'utils/Settings/types';
import { isAppSetting } from 'utils/Settings/utils';
import { parseJSONScalar } from 'utils/parseJsonScalar';
import { useGetSessionData } from 'hooks/queries/useGetSessionData';
import { MAX_SETTINGS } from 'utils/Settings/constants';
import { NonPaginatedEdge } from 'typeDeclarations/graphql/base-types';

// We might be able to optimize this with a better architecture.
export const useSetupApp = () => {
  const { data, loading } = useGetSessionData();

  const setupPermissions = (permissions: Pick<PermissionNode, 'id' | '_id'>[]) => {
    permissionsVar(
      permissions.reduce<PartialRecord<string, boolean>>((acc, permissionNode) => {
        acc[permissionNode._id] = true;

        return acc;
      }, {}),
    );
  };

  const setupSettings = (
    settings: NonPaginatedEdge<Pick<FrontendSettingNode, 'id' | 'name' | '__typename' | 'modified' | 'value'>>[],
  ) => {
    settingsVar(
      settings.reduce<AppSettingsMap>((acc, edge) => {
        const { name, value } = edge.node;

        if (isAppSetting(name)) {
          acc[name] = parseJSONScalar(value);
        }

        return acc;
      }, {}),
    );
  };

  const setupTheme = (appTheme: ThemeName, userTheme: string | null) => {
    const theme = userTheme || appTheme;

    // If the theme is the default or the leadzai theme, we don't need to change it.
    // Note: "advertio" and "leadzai" are from the back-end, but the front-end treats both as "default".
    if (theme === 'default' || theme === 'advertio' || theme === 'leadzai') {
      userThemeVar('default');
      return;
    }

    if (theme in themeNames) {
      userThemeVar(theme as ThemeName);
    }
  };

  useEffect(() => {
    if (!data) return;

    const { getContextPermissions, sessionUserTeamRelation, sessionUser } = data;

    setupTheme(config.theme, sessionUser.theme);
    setupPermissions(getContextPermissions);

    if (!sessionUserTeamRelation) return;

    const {
      frontendSettings: { edges, pageInfo },
    } = sessionUserTeamRelation;

    if (pageInfo.hasNextPage) {
      captureException(
        new Error(
          `The user ${sessionUser.id} has more settings saved than expected. The current maximum amount is ${MAX_SETTINGS}`,
        ),
      );
    }

    setupSettings(edges);
  }, [data]);

  return { loading };
};
