import { LazyQueryHookOptions, useQuery } from '@apollo/client';

import { gql } from '__generated__/gql';
import { PermissionNode, UserTeamsNode } from '__generated__/graphql';
import { config } from 'appConfig';
import { SessionContextQueryFragmentData } from 'components/App/session/SessionContext';
import { NavigationMenuHeaderQueryFragmentData } from 'components/Header/HeaderMenuToggle/HeaderMenuContent/NavigationMenu/NavigationMenuHeader/fragments';
import {
  NUM_INITIAL_TEAMS,
  TeamsMenuQueryFragmentData,
} from 'components/Header/HeaderMenuToggle/HeaderMenuContent/TeamsMenu/TeamsMenu';
import { FrontendSettingConnectionFragmentData } from 'graphql/fragments';
import { AppSessionLocaleQueryFragmentData } from 'hooks/useAppSessionLocale';
import { useDefaultOnError } from 'hooks/useDefaultOnError';
import { RedirectUserThemePartnerQueryFragmentData } from 'hooks/useRedirectUserThemePartner';
import { ShouldShowFirstBusinessPromptQueryFragmentData } from 'hooks/useShouldShowFirstBusinessPrompt';
import { UserUtilsBootQueryFragmentData } from 'hooks/useUtilsBoot';
import { ReferralAnnouncementQueryFragmentData } from 'shared/ReferralAnnouncement';
import { UserTeamAvatarQueryFragmentData } from 'shared/UserTeamAvatar';
import { APIPermission } from 'typeDeclarations/enums';
import { UserTrackingQueryFragmentData } from 'userTracking/useUserTracking';

/**
 * This component fetches a lot of important data at once for caching and optimization purposes.
 */
export const SESSION_QUERY_FRAGMENT = gql(/* GraphQL */ `
  fragment SessionQueryFragment on Query {
    ...TeamsMenuQueryFragment
    ...UserTrackingQueryFragment
    ...UserUtilsBootQueryFragment
    ...UserTeamAvatarQueryFragment
    ...SessionContextQueryFragment
    ...AppSessionLocaleQueryFragment
    ...ReferralAnnouncementQueryFragment
    ...NavigationMenuHeaderQueryFragment
    ...RedirectUserThemePartnerQueryFragment
    ...ShouldShowFirstBusinessPromptQueryFragment
    isLoggedIn
    getContextPermissions(permissions: $permissions) {
      id
      _id
    }
    sessionUserTeamRelation {
      id
      user {
        id
        theme
      }
      frontendSettings(first: 100, frontend: $frontend) {
        ...FrontendSettingConnectionFragment
      }
    }
  }
`);

const SESSION_QUERY = gql(/* GraphQL */ `
  query sessionQuery($teamsAmount: Int!, $frontend: String!, $permissions: [String!]!) {
    ...SessionQueryFragment
  }
`);

export type SessionQueryFragmentData = TeamsMenuQueryFragmentData &
  UserTrackingQueryFragmentData &
  UserUtilsBootQueryFragmentData &
  UserTeamAvatarQueryFragmentData &
  SessionContextQueryFragmentData &
  AppSessionLocaleQueryFragmentData &
  NavigationMenuHeaderQueryFragmentData &
  ReferralAnnouncementQueryFragmentData &
  RedirectUserThemePartnerQueryFragmentData &
  ShouldShowFirstBusinessPromptQueryFragmentData & {
    isLoggedIn: boolean;
    getContextPermissions: Array<Pick<PermissionNode, 'id' | '_id'>>;
    sessionUserTeamRelation:
      | (Pick<UserTeamsNode, 'id'> & { frontendSettings: FrontendSettingConnectionFragmentData })
      | null;
  };

export type SessionQueryVariables = {
  frontend: string;
  teamsAmount: number;
  permissions: string[];
};

export function useGetSessionData(
  options?: Omit<LazyQueryHookOptions<SessionQueryFragmentData, SessionQueryVariables>, 'onError' | 'variables'>,
) {
  const onError = useDefaultOnError();

  return useQuery<SessionQueryFragmentData, SessionQueryVariables>(SESSION_QUERY, {
    ...options,
    onError,
    variables: {
      frontend: config.name,
      teamsAmount: NUM_INITIAL_TEAMS,
      permissions: Object.values(APIPermission),
    },
  });
}
